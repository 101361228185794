import { Image } from '@graphcommerce/image'
import { Footer as FooterBase } from '@graphcommerce/next-ui'
import { Container, IconButton } from '@mui/material'
import { RowLinkFive } from '../GraphCMS/RowLinkFive/RowLinkFive'
import { FooterMobilePageLinks } from './FooterMobilePageLinks'
import { FooterQueryFragment } from './FooterQueryFragment.gql'
import { useRouter } from 'next/router'
import ContainerAdapter from './ContainerAdapter'
import Link from '@mui/material/Link'
import { useEffect, useState } from 'react'
import { isMobile } from '../../utils'
import React from 'react'

export type FooterProps = FooterQueryFragment

export function Footer(props: FooterProps) {
  const { footer } = props
  const rowLinkFivesData = footer?.rowLinkFives ?? []
  const rowLinksMobile = footer?.rowLinksMobile
  const router = useRouter()
  const [client, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  if (
    router.asPath.includes('/search') ||
    router.asPath.includes('/account') ||
    router.asPath.includes('/checkout/klarna') ||
    typeof location === 'undefined' ||
    location.pathname.includes('/account') ||
    !client
  ) {
    return <React.Fragment></React.Fragment>
  }

  return (
    <Container maxWidth={false} disableGutters={true} className='footer'>
      <ContainerAdapter>
        {rowLinksMobile?.pageLinks && <FooterMobilePageLinks links={rowLinksMobile?.pageLinks} />}
        <RowLinkFive
          cols={rowLinkFivesData}
          sx={(theme) => ({
            backgroundColor: theme.palette.filtoria.primary.darkest,
            color: theme.palette.primary.contrastText,
            display: 'none',
            [theme.breakpoints.up('sm')]: { display: 'block' },
            paddingTop: {
              xs: 0,
              md: '48px',
            },
            paddingBottom: {
              xs: 0,
              md: '48px',
            },
            '& a': {
              color: theme.palette.primary.contrastText,
              textDecoration: 'none',
            },
            '& .MuiGrid-item': {
              paddingLeft: {
                md: 0,
                sm: 0,
              },
              '& .MuiBox-root': {
                fontSize: '16px',
              },
              '& >.MuiBox-root:first-of-type': {
                marginBottom: '16px',
              },
              '& >.MuiBox-root:first-of-type .MuiBox-root': {
                fontSize: '18px',
              },
            },
            '& .MuiCollapse-wrapper .MuiBox-root': {
              padding: 0,
              lineHeight: '24px',
              marginBottom: '6px',
            },
          })}
        />
      </ContainerAdapter>
      <Container maxWidth={false} style={{ background: '#F9F9F9' }}>
        <ContainerAdapter>
          <FooterBase
            className='sociallinks-wrap'
            socialLinks={footer?.socialLinks?.map((link) => (
              <IconButton key={link.title} href={link.url} color='inherit' sx={{ padding: 0 }}>
                {link.asset ? (
                  <Image
                    layout='fill'
                    src={link.asset.url}
                    width={22}
                    height={22}
                    unoptimized
                    alt={link.title}
                    sx={(theme) => ({
                      maxHeight: '24px',
                    })}
                    className={`icon-` + link.title.toLowerCase()}
                  />
                ) : (
                  link.title
                )}
              </IconButton>
            ))}
            storeSwitcher={
              <Link
                sx={(theme) => ({
                  display: 'block',
                  textDecorationColor: '#1A1A1B',
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                })}
                href='/service/personvern'
              >
                Personvern og cookies
              </Link>
            }
            // customerService={
            //   <Button href='/service' variant='pill' >
            //     <Trans id='Customer Service' />
            //   </Button>
            // }
            copyright={
              <>
                <span className='copyright-text'>{footer?.copyright}</span>
                {/* {footer?.legalLinks?.map((link) => (
                  <Link key={link.title} href={link.url} color='textPrimary' underline='always'>
                    {link.title}
                  </Link>
                ))} */}
              </>
            }
            sx={() => ({
              backgroundColor: '#F9F9F9',
              gridTemplateColumns: '100% !important',
              justifyItems: 'center !important',
              paddingTop: 0,
            })}
          />
        </ContainerAdapter>
      </Container>
    </Container>
  )
}
